import * as actionLabels from '../../actionLabels';

const initialState = {
  open: false,
  title: '',
  message: '',
  withButton: true,
  buttonText: '',
  notifyType: 1,
  autoClose: true,
  redirectURL: '',
  buttonClick: undefined,
  showPrimaryButton: true,
  showCloseButton: true,
  handleCloseModal: null,
  FooterComponent: '',
  customModalOpen: false,
  customModalType: '',
  tempCustomModalData: null,
  geolocationModalOpen: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionLabels.SHOW_ALERT_MODAL: {
      return {
        ...state,
        title: payload?.title,
        open: true,
        message: payload?.message,
        withButton: payload?.withButton,
        buttonClick: payload?.buttonClick,
        buttonText: payload?.buttonText,
        notifyType: payload?.notifyType,
        autoClose: payload?.autoClose,
        redirectURL: payload?.redirectURL,
        showPrimaryButton: payload?.showPrimaryButton,
        showCloseButton: payload?.showCloseButton,
        handleClick: payload?.handleClick,
        FooterComponent: payload?.FooterComponent
      };
    }
    case actionLabels.HIDE_MODAL: {
      return {
        ...state,
        open: false
      };
    }
    case actionLabels.RESET_MODAL: {
      return {
        open: false,
        title: '',
        message: '',
        withButton: true,
        buttonText: '',
        autoClose: true,
        redirectURL: '',
        buttonClick: undefined,
        showPrimaryButton: true,
        showCloseButton: true,
        handleCloseModal: null,
        FooterComponent: '',
        customModalOpen: false,
        customModalType: '',
        tempCustomModalData: null,
        geolocationModalOpen: false
      };
    }
    default:
      return state;
  }
};
